<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.new_m", {
                m: $t("message.product"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <div class="app-modal__body p-5 pb-0">
          <div class="timeline-items__right rounded-sm w-100 p-4">
              <el-row :gutter="20">
                <el-col :span="8" v-for="sys_lang in systemLanguages" :key="'lang'+sys_lang.code">
                  <el-form-item
                    :label="$t('message.'+sys_lang.code)"
                    class="label_mini"
                  >
                    <el-input
                      :placeholder="$t('message.name')"
                      v-model="form.names[sys_lang.code]"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

              </el-row>
          </div>
        </div>
        <div class="app-modal__body p-5 pb-0">
          <div class="timeline-items__right rounded-sm w-100 p-4">
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-col :span="12">
                    <el-form-item
                      :label="$t('message.name')"
                      class="label_mini"
                      prop="name"
                    >
                      <el-input
                        :placeholder="$t('message.name')"
                        v-model="form.name"
                        size="medium"
                      ></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="12">
                    <el-form-item :label="$t('message.calculation_type')" prop="calculation_type" class="label_mini">
                      <radio-calculation-type
                        v-model="form.calculation_type"
                        :id="form.calculation_type"
                      >
                      </radio-calculation-type>
                    </el-form-item>
                  </el-col>

                  <el-col :span="12">
                    <el-form-item
                      :label="$t('message.currency_id')"
                      class="label_mini"
                      prop="currency_id"
                    >
                      <select-currency
                        ref="Currency"
                        :size="'medium'"
                        :placeholder="columns.currency_id.title"
                        :id="form.currency_id"
                        v-model="form.currency_id"
                      >
                      </select-currency>
                    </el-form-item>
                  </el-col>

                  <el-col :span="12">
                    <el-form-item
                      :label="$t('message.is_active')"
                      class="label_mini"
                      prop="is_active"
                    >
                      <el-switch
                        v-model="form.is_active"
                        :active-value="true"
                        :inactive-value="false"
                        :active-text="$t('message.active')"
                        :inactive-text="$t('message.inactive')">
                      </el-switch>
                    </el-form-item>
                  </el-col>

                  <el-col :span="24">
                    <el-form-item
                      :label="$t('message.copy from minimum weight list of product')"
                      class="label_mini"
                    >

                      <select-product
                        :size="'medium'"
                        :placeholder="$t('message.copy from minimum weight list of product')"
                        v-model="form.copy_product_id"
                      >
                      </select-product>
                    </el-form-item>
                  </el-col>

                </el-col>
              </el-row>
          </div>
        </div>
      </el-form>

      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCurrency from "@/components/inventory/select-currency";
import selectMeasurement from "@/components/inventory/select-measurement";
import selectCategories from "@/components/multiSelects/multi-select-categories";
import RadioCalculationType from "@/components/radioSelect/radio-calculation-type";
import selectProduct from "@/components/inventory/select-product-simple";

import _ from 'lodash';

export default {
  mixins: [form, drawer],
  components: {
    selectCurrency,
    selectMeasurement,
    selectCategories,
    RadioCalculationType,
    selectProduct
  },
  data() {
    return {
      imageList: [],
      updateImage: [],

    };
  },

  computed: {
    ...mapGetters({
      rules: "products/rules",
      model: "products/model",
      columns: "products/columns",
      categories: "categories/inventory",
      measurements: "measurements/inventory",
      systemLanguages: "systemLanguages",
    }),
  },
  methods: {
    ...mapActions({
      save: "products/store",
      empty: "products/empty",
      updateInventory: "products/inventory",
    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {

          let formData = new FormData();

          for (const key in this.form) {
              if (key !== 'is_default' && key !== 'names') {
                  if (this.form[key]) {
                      formData.append(key, this.form[key]);
                  } else {
                      formData.append(key, '');
                  }
              } else if (key == 'is_default') {
                  formData.append(key, this.form[key]);
              }
              else if (key === 'names') {
                  formData.append(key, JSON.stringify(this.form[key]));
              }
          }

          if (_.size(this.updateImage) > 0) {
              for (const key in this.updateImage) {
                  if (this.updateImage.hasOwnProperty(key)) {
                      const element = this.updateImage[key];
                      formData.append(`images[${key}]`, element);
                  }
              }
          }
          this.form.images = formData;
          
          this.loadingButton = true;
          this.save(formData)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.$emit('newInventoryItem');
              this.updateInventory();
              this.parent().listChanged();
              if (close) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },

    updateImageList(file) {
        this.updateImage.push(file.raw);
    },
    handleRemove(file) {
        if (file.id) {
            this.deleteImage({ product_id: this.product ? this.product.id : null, image_id: file.id }).then(res => {
                this.$alert(res);
            }).catch(err => {});
        } else {
            for (var i = 0; i < _.size(this.updateImage); i++) {
                if (this.updateImage[i].name === file.name) {
                    this.updateImage.splice(i, 1);
                }
            }

        }
    },

    defaultChange(e){
      this.form.is_default = e;
    },
    async afterOpen() {
      await this.updateCurrency();
    },
    updateCurrency(){
      setTimeout(() => {
        if(this.$refs['Currency']){
          this.$refs['Currency'].selectedForCreate();
        }
      }, 400);
    },
    afterLeave(){
      this.empty();
      this.imageList = []
    }
  },
};
</script>
